var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":'center'}}),_c('div',{staticClass:"d"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Programme")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("System")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.listLocation.find(function (item) { return item.id === _vm.locationId; }).name)+" ")])]),_vm._m(0)]),_c('tbody',_vm._l((_vm.systemAverage),function(item){return _c('tr',{key:item.programme},[_c('td',[_c('span',[_vm._v(_vm._s(item.programme)+" ")])]),_c('td',[_vm._v(_vm._s(item.nbrClass))]),_c('td',[_vm._v(_vm._s(item.nbrStu))]),_c('td',[_vm._v(_vm._s((item.nbrStu / item.nbrClass).toFixed(1)))]),(_vm.locationArerage.some(function (itemI) { return itemI.programmeID === item.programmeID; }))?[_c('td',[_vm._v(" "+_vm._s(_vm.locationArerage.find(function (itemI) { return itemI.programmeID === item.programmeID; }).nbrClass)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.locationArerage.find(function (itemI) { return itemI.programmeID === item.programmeID; }).nbrStu)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.locationArerage .find(function (itemI) { return itemI.programmeID === item.programmeID; }) .average.toFixed(1))+" ")])]:[_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")])]],2)}),0),_c('tfoot',[_c('tr',[_c('td',[_c('span',{staticClass:"d-flex align-center justify-space-between"},[_c('p',[_vm._v("Grand total")]),_c('v-tooltip',{attrs:{"bottom":"","color":"grey lighten-5","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","fab":"","dark":"","small":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle-outline")])],1)]}}])},[_c('span',{staticClass:"custom-tooltip"},[_c('div',[_c('p',[_vm._v(" GRAND TOTAL: tổng các programe trong hình, ngoại trừ các lớp make up ")])])])])],1)]),_c('td',[_vm._v(_vm._s(_vm.totalAllSys.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalAllSys.nbrStu))]),_c('td',{class:_vm.totalAllSys.color},[_vm._v(_vm._s(_vm.totalAllSys.average))]),_c('td',[_vm._v(_vm._s(_vm.totalAll.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalAll.nbrStu))]),_c('td',{class:_vm.totalAll.color},[_vm._v(_vm._s(_vm.totalAll.average))])]),_c('tr',[_c('td',[_c('span',{staticClass:"d-flex align-center justify-space-between"},[_c('p',[_vm._v("Total group class")]),_c('v-tooltip',{attrs:{"bottom":"","color":"grey lighten-5","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","fab":"","dark":"","small":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle-outline")])],1)]}}])},[_c('span',{staticClass:"custom-tooltip"},[_c('div',[_c('p',[_vm._v("TOTAL GROUP = GRAND TOTAL trừ các lớp private, gym, summer camp")])])])])],1)]),_c('td',[_vm._v(_vm._s(_vm.totalGroupSys.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalGroupSys.nbrStu))]),_c('td',{class:_vm.totalGroupSys.color},[_vm._v(_vm._s(_vm.totalGroupSys.average))]),_c('td',[_vm._v(_vm._s(_vm.totalGroup.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalGroup.nbrStu))]),_c('td',{class:_vm.totalGroup.color},[_vm._v(_vm._s(_vm.totalGroup.average))])]),_c('tr',[_c('td',[_c('span',{staticClass:"d-flex align-center justify-space-between"},[_c('p',[_vm._v("Standard Class")]),_c('v-tooltip',{attrs:{"bottom":"","color":"grey lighten-5","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","fab":"","dark":"","small":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle-outline")])],1)]}}])},[_c('span',{staticClass:"custom-tooltip"},[_c('div',[_c('p',[_vm._v("STANDARD CLASS = TOTAL GROUP trừ intensive grammar, basic grammar")])])])])],1)]),_c('td',[_vm._v(_vm._s(_vm.totalStandareSys.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalStandareSys.nbrStu))]),_c('td',{class:_vm.totalStandareSys.color},[_vm._v(_vm._s(_vm.totalStandareSys.average))]),_c('td',[_vm._v(_vm._s(_vm.totalStandare.nbrClass))]),_c('td',[_vm._v(_vm._s(_vm.totalStandare.nbrStu))]),_c('td',{class:_vm.totalStandare.color},[_vm._v(_vm._s(_vm.totalStandare.average))])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Num of Class")]),_c('th',[_vm._v("Num of Student")]),_c('th',[_vm._v("Average Student")]),_c('th',[_vm._v("Num of Class")]),_c('th',[_vm._v("Num of Student")]),_c('th',[_vm._v("Average Student")])])}]

export { render, staticRenderFns }